<template>
  <div class="container">
    <div class="content">
      <div class="title">乐普是中国心血管领域的龙头企业，我们的AI故事从5300万份数据开始</div>
      <div class="subtitle">使用世界顶级人工智能架构，在高性能GPU加持下，通过卷积神经网络造就乐普优异的AI能力</div>
      <img v-lazy="require('@/assets/img/steps.png')">
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'techBlockCom',
  setup () {}
})
</script>
<style lang="less" scoped>
  .container{
    background: #2078BE1a;
    width: 100%;
    .content{
      width: 1140px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .title{
        margin: 60px 30px;
        margin-bottom: 16px;
        font-size: 2.6rem;
        font-weight: bold;
      }
      .subtitle{
        margin: 0px 40px;
        color: #666666;
        font-size: 1.6rem;
      }
      img{
        max-height: 200px;
        width: 80%;
        object-fit: contain;
        margin: 50px 0;
      }
    }
  }
  @media all and (max-width: 1140px) {
    .container{
      .content{
        width: 100%;
        .title{
          margin: 60px 30px;
          margin-bottom: 20px;
          font-size: 2.8rem;
        }
        .subtitle{
          margin: 0px 30px;
          font-size: 2.2rem;
        }
        img{
          max-height: 150px;
          width: 95%;
          margin: 60px 0;
          object-fit: contain;
        }
      }
    }
  }
  @media all and (max-width: 830px) and (min-width: 560px) {
    .container{
      .content{
        width: 90%;
      }
    }
  }
  @media all and (max-width: 560px) {
    .container{
      .content{
        width: 95%;
        .title{
          margin: 60px 20px;
          margin-bottom: 20px;
          font-size: 2.5rem;
        }
        .subtitle{
          margin: 0px 30px;
          font-size: 1.6rem;
        }
        img{
          max-height: 110px;
        }
      }
    }
  }
</style>
