<template>
  <div class="container">
    <div class="img" v-if="!isLeft">
      <img :src="imgSrc" alt="" class="imageAnime" ref="anime">
    </div>
    <div class="tabs">
      <div class="title">{{content.title}}</div>
      <div :class="{ 'tab': true, 'selected': tab.index === selected }" v-for="tab of content.tabs" :key="tab.index" @mouseenter="tabTo(tab.linkedImg, tab.index)">
        <div class="tabBox">
          <div class="tabIcon">
            <img :src="tab.img">
          </div>
          <div class="tabContent">
            <div class="tabTitle">{{tab.subtitle}}</div>
            <div class="tabDetail">{{tab.detail}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="img" v-if="isLeft">
      <img :src="imgSrc" alt="" class="imageAnime" ref="anime">
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'TabCom',
  props: {
    isLeft: Boolean,
    content: Object
  },
  data () {
    return {
      imgSrc: require('@/assets/img/power1.png'),
      selected: 0
    }
  },
  mounted () {
    this.imgSrc = this.isLeft ? require('@/assets/img/power1.png') : require('@/assets/img/why1.png')
  },
  setup () {
    const FN = 25 // 总帧数
    let count = 0 // 当前帧数
    let rID = null // 帧id
    const anime = ref(null)
    function Anime () {
      const img = anime.value
      if (count <= FN) {
        const k = Math.sqrt(count / FN) // 时间函数,先快后慢
        img.style.right = (400 - 400 * k) + 'px'
        img.style.opacity = k
      } else {
        cancelAnimationFrame(rID)
        rID = null
        count = 0
        return
      }
      count++
      rID = requestAnimationFrame(Anime)
    }

    const tabTo = function (aim, index) {
      this.imgSrc = aim
      if (this.selected !== index) {
        if (rID) {
          cancelAnimationFrame(rID)
          rID = null
          count = 0
        }
        Anime()
      }
      this.selected = index
    }
    return {
      tabTo,
      anime
    }
  }
})
</script>

<style lang="less" scoped>
  .container{
    display: flex;
    align-items: flex-start;
    width: 1140px;
    margin: auto;
    margin-bottom: 90px;
    .img{
      flex: 6;
      background-color: #F4F4F4;
      margin: 0 24px;
      overflow: hidden;
      border-radius: 20px;
      height: 440px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        max-height: 92%;
        max-width: 92%;
        object-fit: contain;
      }
      .imageAnime{
        position: relative;
      }
    }
    .tabs{
      flex: 4;
      text-align: left;
      margin: 6px 24px;
      .title{
        font-size: 2.6rem;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .tab{
        border-radius: 10px;
        .tabBox{
          margin: 0 20px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
        }
        .tabIcon{
          margin: 20px 10px;
          img{
            width: 35px;
            object-fit: contain;
          }
        }
        .tabContent{
          margin: 20px 10px;
          .tabTitle{
            font-size: 1.6rem;
            font-weight: bold;
          }
          .tabDetail{
            color: #666666;
            font-size: 1.3rem;
          }
        }
      }
      .tab:hover, .selected{
        cursor: pointer;
        background-color: #2078BE1a;
      }
    }
  }
  @media all and (max-width: 1140px) and (min-width: 830px){
    .container{
      width: 100%;
      .img{
        flex: 55;
        object-fit: contain;
      }
      .tabs{
        flex: 45;
        .title{
          font-size: 2.4rem;
        }
        .tab{
          .tabContent{
            .tabTitle{
              font-size: 1.4rem;
            }
            .tabDetail{
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 830px) and (min-width: 560px) {
    .container{
      display: block;
      width: 80%;
      position: relative;
      padding-bottom: 400px;
      .img{
        margin: 0 5%;
        height: 400px;
        position: absolute;
        bottom: 0;
        width: 90%;
        img{
          max-height: 90%;
          max-width: 90%;
          object-fit: contain;
        }
      }
      .tabs{
        margin: 20px 0;
        .title{
          font-size: 3.4rem;
        }
        .tab{
          .tabBox{
            margin: 0;
          }
          .tabIcon{
            margin: 10px;
            img{
              width: 50px;
              object-fit: contain;
            }
          }
          .tabContent{
            margin: 10px;
            .tabTitle{
              font-size: 2.4rem;
            }
            .tabDetail{
              color: #666666;
              font-size: 2.0rem;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 560px){
    .container{
      display: block;
      width: 90%;
      position: relative;
      padding-bottom: 280px;
      .img{
        margin: 0 5%;
        height: 280px;
        position: absolute;
        bottom: 0;
        width: 90%;
        img{
          max-height: 90%;
          max-width: 90%;
          object-fit: contain;
        }
      }
      .tabs{
        margin: 12px;
        .title{
          font-size: 2.2rem;
        }
        .tab{
          .tabBox{
            margin: 0;
          }
          .tabIcon{
            margin: 10px;
            img{
              width: 30px;
              object-fit: contain;
            }
          }
          .tabContent{
            margin: 10px;
            .tabTitle{
              font-size: 1.4rem;
            }
            .tabDetail{
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
</style>
