<template>
  <div class="container">
    <div class="title">应用场景</div>
    <div class="cards">
      <div class="card" v-for="card of cards" :key="card.index">
        <img v-lazy="card.img">
        <div class="content" :style="'color:' + card.color">
          <div class="subtitle">{{card.title}}</div>
          <div class="detail">{{card.detail}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CardsCom',
  setup () {
    const cards = [
      {
        title: '养老',
        detail: '通过乐普完备的适老交互方案，协助各类养老机构升级健康服务，完成从健康信息收集到在线诊疗的全套方案。',
        img: require('@/assets/img/card1.png'),
        color: '#F3982D'
      },
      {
        title: '远程健康管理',
        detail: '借助乐普全产品线的设备方案，让向用户提供从检测到建议的全套健康管理方案成为可能。',
        img: require('@/assets/img/card2.png'),
        color: '#3389CA'
      },
      {
        title: '实时遥测',
        detail: '在乐普云的能力支持下，我们帮助合作伙伴稳定每一条数据的实时传输。',
        img: require('@/assets/img/card3.png'),
        color: '#00A682'
      },
      {
        title: '特殊体检需求',
        detail: '运动训练、特殊岗位、防疫支持，不管是有针对性的快速体检，还是深度的身体数据分析，乐普的数字化方案最大可能的支持特殊场景的个性化需求。',
        img: require('@/assets/img/card4.png'),
        color: '#EF857D'
      }
    ]
    return {
      cards
    }
  }
})
</script>

<style lang="less" scoped>
  .container{
    width: 1140px;
    margin: 80px auto;
    .title{
      margin: 20px 0;
      font-size: 2.6rem;
      font-weight: bold;
    }
    .cards{
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .card{
        width: 45%;
        position: relative;
        border-radius: 20px;
        margin: 20px 0;
        img{
          max-height: 265px;
          width: 100%;
          object-fit: contain;
          border-radius: 20px;
        }
        .content{
          position: absolute;
          bottom: 10px;
          left: 10px;
          color: #fff;
          z-index: 10;
          margin: 0 20px;
          text-align: left;
          .subtitle{
            font-size: 2.4rem;
            font-weight: bold;
          }
          .detail{
            font-size: 1.7rem;
          }
        }
      }
      .card::after{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;;
        content: '';
        border-radius: 20px;
      }
    }
  }
  @media all and (max-width: 1140px) and (min-width: 830px){
    .container{
      width: 100%;
      .cards{
        .card{
          .content{
            .detail{
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 830px) and (min-width: 560px) {
    .container{
      width: 100%;
      .title{
        font-size: 3.6rem
      }
      .cards{
        display: block;
        .card{
          margin: 40px auto;
          width: 80%;
          img{
            max-height: 340px;
            width: 100%;
            object-fit: contain;
          }
          .content{
            .subtitle{
              font-size: 3.2rem;
            }
            .detail{
              font-size: 2.2rem;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 560px) and (min-width: 450px) {
    .container{
      width: 100%;
      .cards{
        display: block;
        .card{
          margin: 24px auto;
          width: 90%;
          img{
            max-height: 260px;
            width: 100%;
            object-fit: contain;
          }
          .content{
            .subtitle{
              font-size: 2.4rem;
            }
            .detail{
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 450px) {
    .container{
      width: 100%;
      .cards{
        display: block;
        .card{
          margin: 24px auto;
          width: 90%;
          img{
            max-height: 190px;
            width: 100%;
            object-fit: contain;
          }
          .content{
            margin: 0 10px;
            .subtitle{
              font-size: 2rem;
            }
            .detail{
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
</style>
