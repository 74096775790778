import { createApp } from 'vue'
import App from './App.vue'
import { Button, Form, Input, Modal } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import lazyPlugin from 'vue3-lazy'

createApp(App)
  .use(Button)
  .use(Form)
  .use(Input)
  .use(Modal)
  .use(lazyPlugin, {
    loading: require('@/assets/img/loading.svg'), // 图片加载时默认图片
    error: require('@/assets/img/loading.svg')// 图片加载失败时默认图片
  })
  .mount('#app')
