<template>
  <div class="container">
    <div class="intro" v-for="intro of intros" :key="intro.index">
      <img v-lazy="intro.img">
      <div class="title">{{intro.title}}</div>
      <div class="discription">{{intro.discription}}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IntroductionCom',
  setup () {
    const intros = [
      {
        img: require('@/assets/img/intro1.png'),
        title: '合作9100+医疗机构',
        discription: '截止2021年，乐普医疗已于全国9100+医疗机构展开合作。'
      },
      {
        img: require('@/assets/img/intro2.png'),
        title: '1.6亿份数据',
        discription: '心电实时数据累计量超1.6亿份，动态报告累计服务250万+份，数据存储量居世界前列。'
      },
      {
        img: require('@/assets/img/intro3.png'),
        title: '短短15天，让你的服务脱胎换骨',
        discription: '标准化的SDK和API接口，让你的技术团队可以轻易完成对接任务。'
      }
    ]
    return {
      intros
    }
  }
})
</script>

<style lang="less" scoped>
  .container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 1140px;
    margin: auto;
    margin-bottom: 100px;
    .intro{
      width: 28%;
      img{
        width: 50%;
        object-fit: contain;
      }
      .title{
        font-weight: bold;
        font-size: 2rem;
      }
      .discription{
        font-size: 1.6rem;
        color: #666666;
      }
    }
  }
  @media all and (max-width: 1140px) {
    .container{
      width: 100%;
    }
  }
  @media all and (max-width: 830px) and (min-width: 560px) {
    .container{
      display: block;
      width: 100%;
      .intro{
        width: 80%;
        margin: 70px auto;
        img{
          width: 60%;
        }
        .title{
          font-size: 3.6rem;
        }
        .discription{
          font-size: 2.2rem;
        }
      }
    }
  }
  @media all and (max-width: 560px) {
    .container{
      display: block;
      width: 100%;
      .intro{
        width: 80%;
        margin: 50px auto;
      }
    }
  }
</style>
