<template>
  <div class="container">
    <div class="content">
      <div class="title">服务咨询</div>
      <img v-lazy="require('@/assets/img/doctor2.png')" class="doctor">
      <img v-lazy="require('@/assets/img/cloud2.png')" class="cloud">
      <img v-lazy="require('@/assets/img/rocket.png')" class="rocket">
      <div class="form">
        <a-form
          :model="formState"
          name="basic"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          autocomplete="off"
          @finish="onFinish"
          @finishFailed="onFinishFailed"
        >
          <a-form-item
            label="公司名称"
            name="company_name"
            :rules="[{ message: '不能为空', required: true }]"
          >
            <a-input v-model:value="formState.company_name" size="large"/>
          </a-form-item>
          <a-form-item
            label="您的姓名"
            name="name"
            :rules="[{ message: '不能为空', required: true }]"
          >
            <a-input v-model:value="formState.name" size="large"/>
          </a-form-item>
          <a-form-item
            label="邮箱地址"
            name="email"
            :rules="[{ pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/, message: '邮箱格式不正确', required: true }]"
          >
            <a-input v-model:value="formState.email" size="large"/>
          </a-form-item>
          <a-form-item
            label="电话号码"
            name="phone"
            :rules="[{ pattern: /^((^1(3|4|7|5|8)([0-9]{9}))|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)$/, message: '电话格式不正确', required: true }]"
          >
            <a-input v-model:value="formState.phone" size="large"/>
          </a-form-item>

          <a-form-item :wrapper-col="{ offset: 0, span: 24 }">
            <a-button type="primary" :disabled="loading" :loading="loading" html-type="submit">{{ loading ? '正在咨询': '立即咨询' }}</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { postConsult } from '@/assets/utils/api.js'
// import { CheckCircleOutlined } from '@ant-design/icons-vue'
import { Modal } from 'ant-design-vue'

export default defineComponent({
  name: 'ConsultCom',
  // components: {
  //   CheckCircleOutlined
  // },
  setup () {
    const formState = reactive({
      company_name: '',
      name: '',
      email: '',
      phone: ''
    })
    const loading = ref(false)
    const onFinish = values => {
      console.log('Success:', values)
      loading.value = true
      setTimeout(() => {
        postConsult(values).then((res) => {
          if (res.code === 0) {
            Modal.success({
              content: h('div', {}, [
                h('h2', '发送成功'),
                h('h3', '请您静待我们的回复')
              ]),
              footer: null,
              centered: true,
              okText: '好的'
            })
          } else {
            Modal.error({
              content: h('div', {}, [
                h('h2', '发送失败'),
                h('h3', '网络出了点问题，请您稍后尝试')
              ]),
              footer: null,
              centered: true,
              okText: '好的'
            })
          }
          loading.value = false
          console.log(res)
        }).catch((err) => {
          loading.value = false
          console.log(err)
        })
      }, 500)
    }

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo)
    }

    return {
      formState,
      onFinish,
      onFinishFailed,
      loading
    }
  }

})
</script>

<style scoped lang="less">
  .container{
    width: 100%;
    background-color: #2078BE1a;
    margin-top: 120px;
    .content{
      width: 1140px;
      margin: auto;
      position: relative;
      padding: 20px 0;
      .title{
        margin: 20px 0;
        font-size: 2.4rem;
        font-weight: bold;
      }
      .doctor, .cloud, .rocket{
        object-fit: contain;
        position: absolute;
      }
      .doctor{
        width: 20%;
        left: 20px; top: -60px;
      }
      .cloud{
        width: 12%;
        right: 40px; bottom: -40px;
      }
      .rocket{
        width: 8%;
        left: 30%; bottom: -60px;
      }
      .form{
        width: 40%;
        margin: auto;
      }
      .ant-input{
        border-radius: 6px;
      }
    }
  }
  @media all and (max-width: 1140px) {
    .container{
      .content{
        width: 100%;
      }
    }
  }
  @media all and (max-width: 830px) and (min-width: 560px) {
    .container{
      .content{
        width: 100%;
        .title{
          margin: 30px 0;
          font-size: 3.4rem;
        }
        .doctor{
          width: 28%;
          left: 20px; top: -90px;
        }
        .cloud{
          width: 18%;
          right: 40px; bottom: -30px;
        }
        .rocket{
          width: 12%;
          left: 10%; bottom: -40px;
        }
        .form{
          width: 70%;
        }
      }
    }
  }
  @media all and (max-width: 560px) {
    .container{
      .content{
        width: 100%;
        .doctor{
          width: 24%;
          left: 20px; top: -60px;
        }
        .cloud{
          width: 16%;
          right: 40px; bottom: -30px;
        }
        .rocket{
          width: 12%;
          left: 10%; bottom: -40px;
        }
        .form{
          width: 70%;
        }
      }
    }
  }
  :global(.ant-form-item-label > label) {
    color: #666666 !important;
    font-size: 1.8rem !important;
    line-height: 44px !important;
  }
  :global(.ant-btn-primary) {
    background: #3389CA !important;
    border-color: #3389CA !important;
    border-radius: 50px !important;
    height: 40px !important;
    width: 160px !important;
    font-size: 1.8rem !important;
  }
  :global(.ant-modal-confirm-body > .anticon) {
    font-size: 80px !important;
    float: none !important;
    margin: 0 !important;
  }
  :global(.ant-modal-confirm-body .ant-modal-confirm-content h3) {
    text-align: center !important;
  }
  :global(.ant-modal-confirm .ant-modal-confirm-btns){
    text-align: center !important;
    float: none !important;
    margin: 0 !important;
  }
  :global(.ant-modal-body){
    text-align: center !important;
  }
</style>
