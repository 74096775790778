let baseUrl = ''

switch (process.env.NODE_ENV) {
  case 'development':
    baseUrl = 'https://open.viatomtech.com.cn' // 开发环境
    // baseUrl = 'http://193.169.0.27:8002' // 开发环境
    break
  case 'production':
    baseUrl = 'https://open.lepudev.com/' // 正式服
    // baseUrl = 'https://open.viatomtech.com.cn' // 测试服
    break
}

export default baseUrl
