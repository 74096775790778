import axios from 'axios'
import baseUrl from './baseUrl.js'
import md5 from 'js-md5'

const str = '@tu28^9X'

export function postConsult (param) {
  const url = '/console/service_advisory'
  const timeStamp = new Date().getTime()
  const crypto = md5(timeStamp + str)
  return Request({
    url: url,
    method: 'post',
    data: param,
    headers: {
      t: timeStamp,
      s: crypto
    }
  })
}

// 异常拦截处理器
const errorHandler = (error) => {
  return Promise.reject(error)
}

const Request = axios.create({
  baseURL: baseUrl,
  timeout: 6000
})

Request.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json'
  return config
}, errorHandler)

Request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)
