<template>
  <Header/>
  <Introduction/>
  <Tab :isLeft="true" :content="tabContent1"/>
  <Tab :isLeft="false" :content="tabContent2"/>
  <TechBlock/>
  <Cards/>
  <Consult id="consult"/>
</template>

<script>
import Header from './components/Header.vue'
import Introduction from './components/Introduction.vue'
import Tab from './components/Tab.vue'
import TechBlock from './components/TechBlock.vue'
import Cards from './components/Cards.vue'
import Consult from './components/Consult.vue'

export default {
  name: 'App',
  components: {
    Header,
    Introduction,
    Tab,
    TechBlock,
    Cards,
    Consult
  },
  data () {
    return {
      count: 0,
      tabContent1: {
        title: '我们提供的能力',
        tabs: [
          {
            index: 0,
            img: require('@/assets/img/opensdk.png'),
            subtitle: '乐普云智Open SDK',
            detail: '通过乐普云智Open SDK，您的APP可以集成乐普云智的医疗健康产品，向您的用户提供整体医疗健康计划，并追踪检测您的用户健康状况',
            linkedImg: require('@/assets/img/power1.png')
          },
          {
            index: 1,
            img: require('@/assets/img/AI.png'),
            subtitle: 'AI-ECG',
            detail: '通过乐普云智Open API，您可以在自己的产品上实现心电AI分析和医生解读的能力。乐普云智将助力您的产品向用户提供更有价值的增值服务。',
            linkedImg: require('@/assets/img/power2.png')
          },
          {
            index: 2,
            img: require('@/assets/img/solution.png'),
            subtitle: '完整的医疗服务解决方案',
            detail: '使用乐普云智整体解决方案，您可以向不同行业提供高度定制化的医疗健康服务。乐普云智会适配您的整体方案，提供灵活专业的医疗健康支持。',
            linkedImg: require('@/assets/img/power3.png')
          }
        ]
      },
      tabContent2: {
        title: '为什么选择乐普云智开放平台',
        tabs: [
          {
            index: 0,
            img: require('@/assets/img/shield.png'),
            subtitle: '权威认证',
            detail: '中国首家也是唯一获得美国FDA&欧盟CE&中国NMPA认证的人工智能心电算法',
            linkedImg: require('@/assets/img/why1.png')
          },
          {
            index: 1,
            img: require('@/assets/img/atom.png'),
            subtitle: '丰富的AI-ECG技术参数',
            detail: 'AI-ECG提供超出寻常的分析效率，帮助快速排除异常干扰，优秀的算法提高非典型的心电时间捕捉',
            linkedImg: require('@/assets/img/why2.png')
          },
          {
            index: 2,
            img: require('@/assets/img/heart.png'),
            subtitle: '全品类消费级医疗产品',
            detail: '乐普云智丰富的产品线覆盖心电、血氧、血压、血糖、血脂、体重、体脂、体温等多种生命体征监测，充分满足合作伙伴在不同场景的需求',
            linkedImg: require('@/assets/img/why3.png')
          },
          {
            index: 3,
            img: require('@/assets/img/computer.png'),
            subtitle: '互联网医院资质',
            detail: '乐普云智的互联网医院为合作伙伴提供坚实的医疗服务能力，助力合作伙伴提供专业的在线服务',
            linkedImg: require('@/assets/img/why4.png')
          }
        ]
      }
    }
  },
  beforeCreate () {
    // 用require的方式添加图片地址，直接添加图片地址的话，在build打包之后会查找不到图片，因为打包之后的图片名称会有一个加密的字符串
    const imgs = [
      require('@/assets/img/power1.png'),
      require('@/assets/img/power2.png'),
      require('@/assets/img/power3.png'),
      require('@/assets/img/why1.png'),
      require('@/assets/img/why2.png'),
      require('@/assets/img/why3.png'),
      require('@/assets/img/why4.png')
    ]
    const that = this
    for (const img of imgs) {
      const image = new Image()
      image.src = img
      image.onload = () => {
        that.count++
      }
    }
  },
  mounted () {
    const that = this
    setTimeout(() => {
      that.$nextTick(() => {
        if (window.location.href.split('#')[1] === 'consult') {
          window.scroll({ top: 999999, left: 0, behavior: 'smooth' })
        }
      })
    }, 100)
    setTimeout(() => {
      that.$nextTick(() => {
        if (window.location.href.split('#')[1] === 'consult' && document.documentElement.scrollTop !== document.body.scrollHeight) {
          console.log('scroll to bottom')
          window.scroll({ top: 999999, left: 0, behavior: 'smooth' })
        }
      })
    }, 500)
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
